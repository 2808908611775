<template>
  <div class="fr-container--fluid">
    <div class="fr-grid-row">
      <div class="fr-col-12">
        <nav role="navigation" class="fr-breadcrumb fr-my-1w" aria-label="vous êtes ici :">
          <button class="fr-breadcrumb__button" aria-expanded="false" aria-controls="breadcrumb-1">
            Voir le fil d’Ariane
          </button>
          <div class="fr-collapse" id="breadcrumb-1">
            <ol class="fr-breadcrumb__list">
              <li>
                <a class="fr-breadcrumb__link" href="/accueil" id="fr-breadcrumb-accueil">Accueil</a>
              </li>
              <li>
                <a class="fr-breadcrumb__link" aria-current="page" :id="'fr-breadcrumb-' + PageTitle">{{ PageTitle
                  }}</a>
              </li>
            </ol>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    PageTitle: {
      default: "Vue d'ensemble",
      type: String,
      required: true,
    }
  },
  methods: {
  },
};
</script>
