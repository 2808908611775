<template>
  <footer class="fr-footer" role="contentinfo" id="footer">
    <div class="fr-container--fluid fr-ml-2w">
      <div class="fr-footer__body">
        <div class="fr-footer__brand fr-enlarge-link">
          <a id="fr-logo-link-gouvernement" href="https://www.info.gouv.fr" title="Lien vers le site info.gouv.fr">
            <p class="fr-logo">Gouvernement</p>
          </a>
        </div>
        <div class="fr-footer__content">
          <p class="fr-footer__content-desc">
            Ce site est géré par le Service d’information du Gouvernement (SIG).
          </p>
          <ul class="fr-footer__content-list">
            <li class="fr-footer__content-item">
              <a id="fr-link-legifrance" class="fr-footer__content-link" target="_blank" rel="noopener external"
                title="legifrance.gouv.fr - nouvelle fenêtre" href="https://legifrance.gouv.fr">legifrance.gouv.fr</a>
            </li>
            <li class="fr-footer__content-item">
              <a id="fr-link-gouvernement" class="fr-footer__content-link" target="_blank" rel="noopener external"
                title="Info.gouv.fr - nouvelle fenêtre" href="https://www.info.gouv.fr">info.gouv.fr</a>
            </li>
            <li class="fr-footer__content-item">
              <a id="fr-link-service-public" class="fr-footer__content-link" target="_blank" rel="noopener external"
                title="service-public.fr - nouvelle fenêtre" href="https://service-public.fr">service-public.fr</a>
            </li>
            <li class="fr-footer__content-item">
              <a id="fr-link-data-gouv" class="fr-footer__content-link" target="_blank" rel="noopener external"
                title="data.gouv.fr - nouvelle fenêtre" href="https://data.gouv.fr">data.gouv.fr</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="fr-footer__bottom">
        <ul class="fr-footer__bottom-list">
          <li class="fr-footer__bottom-item">
            <!-- <a id="fr-link-accessibilite" class="fr-footer__bottom-link"
              title="Accessibilité : non/partiellement/totalement conforme"  href="#">Accessibilité :
              non/partiellement/totalement conforme</a> -->
              <router-link id="fr-nav-vue-synthetique" class="fr-footer__bottom-link"
              :to="{ name: 'accessibilite' }" target="_self" title="Accessibilité">Accessibilité :
             partiellement conforme</router-link>

          </li>
          <li class="fr-footer__bottom-item">
            <router-link id="fr-nav-vue-synthetique" class="fr-footer__bottom-link"
              :to="{ name: 'donnees-personnelles' }" target="_self" title="Données personnelles">Données
              personnelles</router-link>
          </li>
          <li class="fr-footer__bottom-item">
            <router-link id="fr-nav-vue-synthetique" class="fr-footer__bottom-link" :to="{ name: 'mentions-legales' }"
              target="_self" title="Mentions légales">Mentions légales</router-link>
          </li>
          <li class="fr-footer__bottom-item">
            <a id="fr-footer-link-cookies" class="fr-footer__bottom-link" href="#" onclick="tarteaucitron.userInterface.openPanel();"
            title="Gestion des cookies">Gestion des cookies</a>
          </li>
          <li class="fr-footer__bottom-item">
            <router-link id="fr-nav-vue-synthetique" class="fr-footer__bottom-link"
              :to="{ name: 'plan-du-site' }" target="_self" title="Plan du site">Plan du site</router-link>
          </li>
        </ul>

        <div class="fr-footer__bottom-copy">
          <p>
            Sauf mention explicite de propriété intellectuelle détenue par des
            tiers, les contenus de ce site sont proposés sous
            <a id="fr-link-etalab-2_0" href="https://github.com/etalab/licence-ouverte/blob/master/LO.md"
              target="_blank" title="licence etalab-2.0 - nouvelle fenêtre">licence
              etalab-2.0</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterDsfr"
};
</script>
