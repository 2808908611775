const state = {
    isMainScreen: true,
    isCompared: false,
    isDashboard: false,
    isSticky: true
};

const getters = {
    isMainScreen: (state) => state.isMainScreen,
    isCompared:(state) => state.isCompared,
    isDashboard: (state) => state.isDashboard,
    isSticky: (state) => state.isSticky,
};

const actions = {
    setMainScreen({ commit }, isMainScreen) {
        commit('SET_IS_MAIN_SCREEN', isMainScreen);
    },
    setIsCompared({commit}, isCompared) {
        commit('SET_IS_COMPARED', isCompared);
    },
    setIsDashboard({commit}, isDashboard) {
        commit('SET_IS_DASHBOARD', isDashboard);
    },
    setIsSticky({commit}, isSticky) {
        commit('SET_IS_STICKY', isSticky);
    },
}

const mutations = {
    SET_IS_COMPARED(state, isCompared){
        state.isCompared = isCompared;
    },
    SET_IS_MAIN_SCREEN(state, isMainScreen) {
        state.isMainScreen = isMainScreen;
    },
    SET_IS_DASHBOARD(state, isDashboard) {
        state.isDashboard = isDashboard;
    },
    SET_IS_STICKY(state, isSticky) {
        state.isSticky = isSticky;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};