<template>
  <nav class="fr-nav" id="navigation-773" role="navigation" aria-label="Menu principal">
    <ul class="fr-nav__list">
      <li class="fr-nav__item">
        <router-link id="fr-nav-accueil" class="fr-nav__link" :to="{ name: 'accueil' }" target="_self"
          title="Accueil">Accueil</router-link>
      </li>
      <li class="fr-nav__item">
        <button class="fr-nav__btn" aria-expanded="false" aria-controls="menu-774" title="Tableaux de bord">Tableaux de
          bord</button>
        <div class="fr-collapse fr-menu" id="menu-774">
          <ul class="fr-menu__list" style="z-index:21;position:relative">
            <li><router-link id="fr-nav-vue-synthetique" class="fr-nav__link" :to="{ name: 'vue-synthetique' }" target="_self"
                title="Principaux indicateurs">Principaux indicateurs</router-link> </li>
            <li><router-link id="fr-nav-visibilite" class="fr-nav__link" :to="{ name: 'visibilite' }" target="_self"
                title="Visibilité">Visibilité</router-link> </li>
            <li><router-link id="fr-nav-acteurs" class="fr-nav__link" :to="{ name: 'acteurs' }" target="_self" title="Acteurs">Acteurs</router-link>
            </li>
            <li><router-link id="fr-nav-sujets-interets" class="fr-nav__link" :to="{ name: 'sujets-interets' }" target="_self"
                title="Sujets d'intérêts">Sujets d'intérêts</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="fr-nav__item">
        <router-link class="fr-nav__link" :to="{ name: 'glossaire-et-ressources' }" target="_self"
          title="Glossaire et ressources" id="fr-nav-glossaire-ressources">Glossaire et ressources</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavigationDsfr",
  data() {
    return {
      isSticky: true,
    };
  },
};
</script>