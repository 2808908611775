import Vue from 'vue'
import App from './App.vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import router from './router'
import store from "./store/store";

// Tarteaucitron + DSFR_analytics
import config_file from './services/tarteaucitron_config.js'
import analytics_config_file from './services/dsfr_analytics_config.js'

// Importation des widgets
const linkElement = document.createElement('link')
linkElement.setAttribute('rel', 'stylesheet')
linkElement.setAttribute('href', 'https://barometre.gouvernement.fr/widgets/css/app.css')
document.head.appendChild(linkElement)

const scriptElement = document.createElement('script')
scriptElement.setAttribute('src', 'https://barometre.gouvernement.fr/widgets/js/app.js')
document.body.appendChild(scriptElement)
// Fin importation des widgets

// CSS
// require('../dsfr-dev/dist/legacy/legacy.nomodule.min.js')
require('../node_modules/@gouvfr/dsfr/dist/legacy/legacy.nomodule.min.js')
require('../node_modules/@gouvfr/dsfr/dist/dsfr.main.css')
require('../node_modules/@gouvfr/dsfr/dist/utility/utility.main.css')
require('../node_modules/@gouvfr/dsfr/dist/dsfr.css')
require('../node_modules/@gouvfr/dsfr/dist/utility/icons/icons-system/icons-system.min.css')

if(process.env.VUE_APP_TRACKING !== ''){
  require('../public/tarteaucitron/tarteaucitron.js')
  require('../public/tarteaucitron/tarteaucitron.services.js')
  require('../public/tarteaucitron/css/dsfr-theme-tac.css')
  tarteaucitronForceLanguage = 'fr';
  tarteaucitron.init(config_file);
  // (tarteaucitron.job = tarteaucitron.job || []).push('youtube');
  tarteaucitron.user.eulerianHost = process.env.VUE_APP_TRACKING;
  // (tarteaucitron.job = tarteaucitron.job || []).push('eulerian-analytics');

  //DSFR Analytics
  window.dsfr = analytics_config_file
}
require('../node_modules/@gouvfr/dsfr/dist/dsfr.module.min.js')
require('../node_modules/@gouvfr/dsfr/dist/scheme/scheme.module.min.js')
if(process.env.VUE_APP_TRACKING !== ''){
   require('../node_modules/@gouvfr/dsfr/dist/analytics/analytics.module.js')}


Vue.config.productionTip = false

if (process.env.VUE_APP_KEYCLOAK_AVAILABLE === 'true') {
  Vue.use(VueKeyCloak, {
    config: {
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      url: process.env.VUE_APP_KEYCLOAK_URL,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT
    },
    init: {
      // Use 'login-required' to always require authentication
      // If using 'login-required', there is no need for the router guards in router.js
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + process.env.VUE_APP_PREFIX_PATH + '/silent-check-sso.html'
    }
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')