<template>
    <div class="fr-skiplinks">
        <nav class="fr-container" role="navigation" aria-label="Accès rapide">
            <ul class="fr-skiplinks__list">
                <li>
                    <a id="fr-skip-link-content" class="fr-link" href="#content" title="Contenu">Contenu</a>
                </li>
                <li>
                    <a id="fr-skip-link-header-navigation" class="fr-link" href="#navigation-773" title="Menu">Menu</a>
                </li>
                <li>
                    <a id="fr-skip-link-footer" class="fr-link" href="#footer" title="Pied de page">Pied de page</a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "SkipLinksDsfr"
}
</script>