<template>
  <header role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container--fluid fr-ml-2w">
        <div class="fr-header__body-row">
          <div class="fr-header__brand">
            <div class="fr-header__brand-top">
              <div class="fr-header__logo">
                <a href="https://www.info.gouv.fr/" title="Lien vers le site Info.gouv.fr">
                  <p class="fr-logo">
                    Gouvernement
                  </p>
                </a>
              </div>
              <div class="fr-header__navbar">
                <button class="fr-btn--menu fr-btn fr-mr-3w" data-fr-opened="false" aria-controls="modal-491"
                  aria-haspopup="menu" id="button-492" title="Menu">
                  Menu
                </button>
              </div>
            </div>

            <div class="fr-header__service">
              <div class="fr-header__service-content">
                <router-link id="fr-header-link-accueil" :to="{ name: 'accueil' }" target="_self"
                  title="Accueil - Observatoire des priorités du gouvernement - SIG">
                  <p class="fr-header__service-title inline-paragraph"> Observatoire des priorités du gouvernement</p>
                </router-link>
                <p class="fr-badge fr-badge--success fr-badge--no-icon inline-paragraph">Bêta</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="fr-header__menu fr-modal" id="modal-491" aria-labelledby="button-492">
        <div class="fr-container fr-ml-1w">
          <button class="fr-btn--close fr-btn" aria-controls="modal-491" title="Fermer">
            Fermer
          </button>
          <div class="fr-header__menu-links">
          </div>
          <app-navigation></app-navigation>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import NavigationDsfr from './Navigation.vue';

export default {
  name: "HeaderDsfr",
  components: {
    "app-navigation": NavigationDsfr
  }
}
</script>

<style>
@import '../styles/components/header.scss';
</style>
