<template>
  <div class="fr-container--fluid fr-p-2w sidenav" role="navigation" aria-label="Menu latéral">
    <div class="fr-grid-row">
      <div class="fr-col-12">
        <div class="sidenav__container fr-p-0">
          <h2 class="fr-h6 fr-pb-1v fr-pt-4w" id="fr-sidemenu-title">
            Affiner la recherche
          </h2>
          <button class="sidenav__container--close-btn fr-my-2w">
            <span class="fr-icon-close-line" @click="closeSidenav" aria-hidden="true" title="Fermer"></span>
          </button>
        </div>

        <div class="sidenav__line fr-p-0"></div>

        <div class="fr-tags-group fr-py-1w">
          <button v-for="(axe, index) in axes" :key="index" class="fr-tag" :aria-pressed="axe.selected"
                  :class="{ 'disabled': axe.selected && !screen }"
                  :id="'fr-dashboard-tag-' + axe['value']" :value="axe['value']" v-on:click="selectAxe(axe)"
                  :title="axe['label']"
          >
            {{ axe['label'] }}
          </button>
        </div>

        <div class="sidenav__line fr-p-0"></div>

        <fieldset class="fr-fieldset" id="radio-hint"
                  aria-labelledby="radio-hint-legend radio-hint-messages fr-btn-reinitialiser">
          <legend class="fr-fieldset__legend--bold fr-fieldset__legend" id="radio-hint-legend">
            Période prédéfinie
          </legend>
          <div class="fr-fieldset__element">
            <div class="fr-radio-group">
              <input v-model="selectedPeriod" type="radio" id="radio-hint-comparison-1" name="radio-hint"
                     value="année" @change="changePeriod"/>
              <label class="fr-label" for="radio-hint-comparison-1" title="Année">Moins d'un an</label>
            </div>
          </div>
          <div class="fr-fieldset__element">
            <div class="fr-radio-group">
              <input v-model="selectedPeriod" type="radio" id="radio-hint-comparison-3" name="radio-hint"
                     value="mois" @change="changePeriod"/>
              <label class="fr-label" for="radio-hint-comparison-3" title="Mois">
                Moins d'un mois</label>
            </div>
          </div>
          <div class="fr-fieldset__element">
            <div class="fr-radio-group">
              <input v-model="selectedPeriod" type="radio" id="radio-hint-comparison-5" name="radio-hint"
                     value="semaine" @change="changePeriod"/>
              <label class="fr-label" for="radio-hint-comparison-5" title="Semaine">
                Moins d'une semaine</label>
            </div>
          </div>
        </fieldset>

        <div class="sidenav__line fr-p-0"></div>

        <fieldset class="fr-fieldset" id="label-hint"
                  aria-labelledby="text-label-hint-legend text-input-start-date-messages text-input-end-date-messages fr-btn-tertiary-reinitialiser">
          <legend class="fr-fieldset__legend--bold fr-fieldset__legend" id="text-label-hint-legend">
            Période personnalisée
          </legend>

          <div class="fr-fieldset__element ">
            <div class="fr-input-group">
              <span class="fr-hint-text fr-mb-2w">Format attendu : jj/mm/aaaa</span>

              <label class="fr-label fr-text--bold" for="text-input-start-date">
                Période du
              </label>
              <input class="fr-input fr-input--error" aria-describedby="text-input-date-messages"
                     id="text-input-start-date" type="date" v-model="selectedStartDatePicker"
                     @change="changeStartDatePicker"
                     title="Afficher le sélecteur de dates"/>
              <p v-if="isNotRightStartDate" id="text-input-error-desc-error" class="fr-error-text">
                La date séléctionnée n'est pas disponible
              </p>
              <div class="fr-messages-group" id="text-input-start-date-messages" aria-live="assertive">
              </div>
            </div>
          </div>

          <div class="fr-fieldset__element">
            <div class="fr-input-group">
              <label class="fr-label fr-text--bold fr-mt-2w" for="text-input-end-date">
                au
              </label>
              <input class="fr-input fr-input--error" aria-describedby="text-input-date-messages"
                     id="text-input-end-date" name="end-date-input" type="date" v-model="selectedEndDatePicker"
                     @change="changeEndDatePicker" title="Afficher le sélecteur de dates"/>
              <p v-if="isNotRightEndDate" id="text-input-error-desc-error" class="fr-error-text">
                La date séléctionnée n'est pas valide
              </p>
              <p v-if="isNotRightPeriod" id="text-input-error-desc-error" class="fr-error-text">
                La période séléctionnée est invalide
              </p>
              <div class="fr-messages-group" id="text-input-end-date-messages" aria-live="assertive"></div>
            </div>
          </div>
        </fieldset>

        <button class="fr-btn fr-btn--tertiary fr-mt-1w fr-mb-4w fr-btn--icon-right fr-icon-close-circle-line"
                @click="resetPresetDates" :disabled="isResetDisabledPresetPeriod" title="Réinitialiser"
                id="fr-btn-tertiary-reinitialiser">
          Réinitialiser
        </button>

        <div class="sidenav__line fr-p-0" ></div>

        <div class="fr-tags-group fr-py-1w">
          <button v-for="(source, index) in sources" :key="index" class="fr-tag" :aria-pressed="source.selected"
                  :id="'fr-dashboard-tag-' + source['value']" :value="source['value']" v-on:click="selectSource(source)"
                  :title="source['label']">
            {{ source['label'] }}
          </button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Sidenav",
  data() {
    return {
      isNotRightStartDate: false,
      isNotRightEndDate: false,
      isNotRightPeriod: false
    };
  },
  computed: {
    ...mapGetters([
      'isMainScreen',
      'getAxes',
      'getSelectedAxes',
      'getPeriod',
      'getSelectedPeriod',
      'getSelectedStartDatePicker',
      'getSelectedEndDatePicker',
      'getSources'
    ]),
    isResetDisabledPresetPeriod() {
      return this.selectedStartDatePicker === undefined && this.selectedEndDatePicker === undefined;
    },
    screen: {
      get() {
        return this.isMainScreen;
      },
    },
    axes: {
      get() {
        return this.getAxes;
      },
      set(value) {
        this.setAxes(value);
      }
    },
    selectedPeriod: {
      get() {
        return this.getSelectedPeriod;
      },
      set(value) {
        this.setSelectedPeriod(value);
      }
    },
    selectedStartDatePicker: {
      get() {
        return this.getSelectedStartDatePicker;
      },
      set(value) {
        this.setSelectedStartDatePicker(value);
      }
    },
    selectedEndDatePicker: {
      get() {
        return this.getSelectedEndDatePicker;
      },
      set(value) {
        this.setSelectedEndDatePicker(value);
      }
    },
    sources: {
      get() {
        return this.getSources;
      },
      set(value) {
        this.setSources(value);
      }
    },
  },
  methods: {
    ...mapActions([
      'setIsSidenavVisible',
      'setAxes',
      'setSources',
      'setSelectedAxes',
      'addSelectedAxe',
      'removeSelectedAxe',
      'setPeriod',
      'setStartDate',
      'setEndDate',
      'setSelectedPeriod',
      'setSelectedStartDatePicker',
      'setSelectedEndDatePicker'
    ]),
    closeSidenav() {
      this.setIsSidenavVisible(false);
    },
    // Génère la listes des axes selon la vue
    generateAxes(axes) {
      // si pas mainScreen, supprimer ALL
      if (!this.isMainScreen) {
        // Supprimer l'élément avec value 'ALL' s'il est présent
        let filteredAxes = this.axes.filter(axe => axe.value !== 'ALL');
        this.setAxes(filteredAxes);
      } else {
        // Ajouter l'élément avec value 'ALL' s'il n'est pas présent
        const isAllAxes = axes.some(axe => axe.value === 'ALL');
        if (!isAllAxes) {
          // Ajouter 'ALL' en première position dans axes
          this.axes.unshift({value: 'ALL', label: 'Tous les axes', selected: false});
        }
      }
    },
    // Met à jour la listes des axes selon les règles de changement de vue
    updateSelections(selectedAxes) {
      this.axes.forEach(axe => {
        axe.selected = selectedAxes.includes(axe.value);
      });
    },
    // Ajuste la selection selon les règles de la vue
    selectAxe(selectedAxe) {
      if (!this.isMainScreen) {
        this.updateSelections([selectedAxe.value]);
        this.setSelectedAxes([selectedAxe.value]);
      }
      if (this.isMainScreen) {
        selectedAxe.selected = !selectedAxe.selected;
        // Si ALL
        if (selectedAxe.value === 'ALL') {
          this.setSelectedAxes(['ALL']);
          this.axes.forEach(axe => {
            axe.selected = axe.value === 'ALL';
          });
        }
        // Si pas ALL
        if (selectedAxe.value !== 'ALL') {
          // Passe le selected de l'axe ALL à false
          this.axes.forEach(axe => {
            if (axe.value === 'ALL') {
              axe.selected = false;
            }
          });
          this.removeSelectedAxe('ALL');
          // et selectionné
          if (selectedAxe.selected) {
            this.addSelectedAxe(selectedAxe.value);
          } else { // et deselectionné
            this.removeSelectedAxe(selectedAxe.value);
          }
        }
      }
    },
    changePeriod(event) {
      this.selectedPeriod = event.target.value;
      this.setPeriod(event.target.value);
      this.setSelectedStartDatePicker(undefined);
      this.setStartDate(undefined);
      this.setSelectedEndDatePicker(undefined);
      this.setEndDate(undefined);
    },
    changeStartDatePicker(event) {
      this.isResetDisabled = false;
      this.setSelectedStartDatePicker(event.target.value);
      this.setStartDate(event.target.value);
      if (this.getSelectedEndDatePicker !== undefined) {
        this.selectedPeriod = undefined;
        this.setPeriod("mois");
      }
    },
    changeEndDatePicker(event) {
      this.isResetDisabled = false;
      this.setSelectedEndDatePicker(event.target.value);
      this.setEndDate(event.target.value);
      if (this.getSelectedStartDatePicker !== undefined) {
        this.selectedPeriod = undefined;
        this.setPeriod("mois");
      }
    },
    resetPresetDates() {
      this.selectedPeriod = 'mois';
      this.setPeriod('mois');
      this.setSelectedStartDatePicker(undefined);
      this.setStartDate(undefined);
      this.setSelectedEndDatePicker(undefined);
      this.setEndDate(undefined);
    },
    selectSource(selectedSource) {
      const sourceIndex = this.sources.findIndex(source => source.value === selectedSource.value);
      this.sources[sourceIndex].selected = !this.sources[sourceIndex].selected;
    }
  },
  mounted() {
    this.generateAxes(this.axes);
    this.updateSelections(this.getSelectedAxes);
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/sidenav.scss";
</style>
