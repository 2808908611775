export function dsfrAnalytics (params = {}) {    
    try {
        localStorage.setItem("dsfr_analytics", "available");
        try {
            if(_EA_disabled() != 1 ){
                console.log("Dsfr: call page ------------ "+params.path)

                window.dsfr.analytics.page.path = params.path;
                window.dsfr.analytics.page.title = params.title;
                window.dsfr.analytics.page.name = params.name;
                window.dsfr.analytics.page.theme = params.theme;
                // window.dsfr.analytics.page.date = get_data();

                if ("isError" in params){
                    window.dsfr.analytics.site.error = params.isError;
                }
                // Envoi des données                
                window.dsfr.analytics.isDebugging = (process.env.VUE_APP_DEBUGGING === "true")
                window.dsfr.analytics.collect();
            }else console.log("Eulerian disabled")

        } catch (error) {
            console.error('Error: ', error)
        }
    }
    catch (err) {
        console.warn("Dsfr analytics failed to be set; Cookies Blocked!");
    }

}
export default dsfrAnalytics

export function EA_push (params = {}) {    
    try {
        if(_EA_disabled() != 1){
                       
            window.EA_datalayer = [];            
            // Informations de page :
            window.EA_datalayer.push('path', params.path);
            window.EA_datalayer.push('title', params.title);
            window.EA_datalayer.push('name', params.name);
            window.EA_datalayer.push('theme', params.theme);
            window.EA_datalayer.push('site-segment', 'observatoire-communication');
            window.EA_datalayer.push('page_template', 'observatoire-communication');
            window.EA_datalayer.push('pagegroup', 'observatoire-communication');
            window.EA_datalayer.push('pagelabel', ['observatoire-communication', '', '', '', '']);
            window.EA_datalayer.push('page_date', get_data());

            // Informations de site :
            window.EA_datalayer.push('site_entity', 'SPM || SIG');
            window.EA_datalayer.push('site_environment', process.env.VUE_APP_ENV);
            window.EA_datalayer.push('site_target', 'information');
            window.EA_datalayer.push('site_type', 'standard');

            if ("isError" in params){
                window.EA_datalayer.push('error', params.isError);
            }

            // Envoi des données :
            window.EA_push(window.EA_datalayer); 
        }

    } catch (error) {
        console.error('Error: ', error)
    }
}

export function get_data () {    
    var newdate = new Date()
    newdate.setDate( (new Date()).getDate() )    
    return newdate.toISOString().split('T')[0]    
}