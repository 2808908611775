import Vue from "vue";
import Router from "vue-router";
import dsfrAnalytics from "./services/dsfr_analytics";
import store from "./store/store";

Vue.use(Router);

let authenticated_required;

process.env.VUE_APP_KEYCLOAK_AVAILABLE === "true" ? (authenticated_required = true) : (authenticated_required = false);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: () =>
                import("./views/AccueilPage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Observatoire des priorités du gouvernement",
                theme: 'Accueil',
                dsfrAnalytics: true
            },
            redirect: "/accueil"
        },
        {
            path: "/accueil",
            name: "accueil",
            component: () =>
                import("./views/AccueilPage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Accueil | Observatoire des priorités du gouvernement",
                theme: 'Accueil',
                dsfrAnalytics: true
            },
        },
        {
            path: "/tableaux-de-bord",
            name: "tableaux-de-bord",
            component: () =>
                import("./views/DashboardPage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                theme: 'Tableaux de bord',
                title: "Tableaux de bord",

                dsfrAnalytics: true
            },
            children: [
                {
                    path: "/indicateurs",
                    name: "vue-synthetique",
                    component: () =>
                        import("./focus/SyntheticView.vue"),
                    meta: {
                        requiresAuth: authenticated_required,
                        title: "Principaux indicateurs | Observatoire des priorités du gouvernement",
                        theme: 'Principaux indicateurs',
                        dsfrAnalytics: true
                    }
                },
                {
                    path: "/visibilite",
                    name: "visibilite",
                    component: () =>
                        import("./focus/FocusVisibility.vue"),
                    meta: {
                        requiresAuth: authenticated_required,
                        title: "Vue détaillée visibilité | Observatoire des priorités du gouvernement",
                        theme: 'Visibilité',
                        dsfrAnalytics: true
                    }
                },
                {
                    path: "/acteurs",
                    name: "acteurs",
                    component: () =>
                        import("./focus/FocusActors.vue"),
                    meta: {
                        requiresAuth: authenticated_required,
                        title: "Vue détaillée acteurs | Observatoire des priorités du gouvernement",
                        theme: 'Acteurs',
                        dsfrAnalytics: true
                    }
                },
                {
                    path: "/sujets-interets",
                    name: "sujets-interets",
                    component: () =>
                        import("./focus/FocusSubjects"),
                    meta: {
                        requiresAuth: authenticated_required,
                        title: "Vue détaillée sujets d'intérêts | Observatoire des priorités du gouvernement",
                        theme: "Sujets d'intérêts",
                        dsfrAnalytics: true
                    }
                },
            ],
        },
        {
            path: "/glossaire-et-ressources",
            name: "glossaire-et-ressources",
            component: () =>
                import("./views/LexiquePage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Glossaire et ressources | Observatoire des priorités du gouvernement",
                theme: "Glossaire et ressources",
                dsfrAnalytics: true
            },
        },
        {
            path: "/mentions-legales",
            name: "mentions-legales",
            component: () =>
                import("./views/LegalMentions.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Mentions légales | Observatoire des priorités gouvernementales",
                theme: "Mentions légales",
                dsfrAnalytics: true
            },
        },
        {
            path: "/donnees-personnelles",
            name: "donnees-personnelles",
            component: () =>
                import("./views/PrivateDataPage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Données personnelles et cookies | Observatoire des priorités gouvernementales",
                theme: "Données personnelles",
                dsfrAnalytics: true
            },
        },
        {
            path: "/accessibilite",
            name: "accessibilite",
            component: () =>
                import("./views/AccessibilityPage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Accessibilité",
                theme: "Accessibilité",
                dsfrAnalytics: true
            },
        },
        {
            path: "/plan-du-site",
            name: "plan-du-site",
            component: () =>
                import("./views/PlanDuSitePage.vue"),
            meta: {
                requiresAuth: authenticated_required,
                title: "Plan du Site | Observatoire des priorités gouvernementales",
                theme: "Plan du Site",
                dsfrAnalytics: true
            }
        },
    ],
    scrollBehavior(to, from, savePosition) {
        return {x: 0, y: 0};
    },
});

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, from, next) => {

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // We wait for Keycloak init, then we can call all methods safely
        while (router.app.$keycloak.createLoginUrl === null) {
            await sleep(100);
        }
        if (router.app.$keycloak.authenticated) {
            next();
        } else {
            const loginUrl = router.app.$keycloak.createLoginUrl();
            console.log("loginUrl " + loginUrl);
            window.location.replace(loginUrl);
        }
    } else {
        next();
    }
});

router.beforeEach(async (to, from, next) => {
    if (process.env.VUE_APP_TRACKING !== '') {
        if (to.meta.dsfrAnalytics) {
            await sleep(900);
            dsfrAnalytics({
                path: to.path,
                name: to.name,
                title: to.meta.title,
                theme: to.meta.theme,
            });
        }
    }
    if (to.name === 'accueil' || to.name === 'glossaire-et-ressources') {
        await store.dispatch('setIsSidenavVisible', false);
        await store.dispatch('setIsDashboard', false);
    }
    if (to.name === 'vue-synthetique' || to.name === 'visibilite' || to.name === 'acteurs' || to.name === 'sujets-interets') {
        await store.dispatch('setIsDashboard', true);
    }
    await store.dispatch('setMainScreen', to.name === 'vue-synthetique');
    if (to.name === 'vue-synthetique') {
        await store.dispatch('setSelectedAxes', ['ALL']);
    } else if (store.getters.getSelectedAxes.includes('ALL') || store.getters.getSelectedAxes.length !== 1) {
        await store.dispatch('setSelectedAxes', ['PLEIN EMPLOI']);
    }
    next();
});

export default router;
