const state = {
    isSidenavVisible: false,
    axes: [
        {value: 'ALL', label: 'Tous les axes', selected: true},
        {value: 'PLEIN EMPLOI', label: 'Plein emploi et Réindustrialisation', selected: false},
        {value: 'TRANSITION ECOLOGIQUE', label: 'Transition écologique', selected: false},
        {value: 'ORDRE REPUBLICAIN ET ENGAGEMENT', label: 'Ordre républicain et engagement', selected: false},
        {value: 'NOUVEAUX PROGRES ET SERVICES PUBLICS', label: 'Nouveaux progrès et services publics', selected: false}
    ],
    selectedAxes: ['ALL'],
    period: "mois",
    startDate: undefined,
    endDate: undefined,
    selectedPeriod: "mois",
    selectedStartDatePicker: undefined,
    selectedEndDatePicker: undefined,
    sources: [
        {value: 'Onclusive', label: 'Exposition médiatique', selected: true},
        {value: 'Talkwalker', label: 'Réseaux sociaux', selected: true},
        {value: 'Aday', label: 'Presse', selected: true}
    ],
};

const getters = {
    isSidenavVisible: (state) => state.isSidenavVisible,
    getAxes: (state) => state.axes,
    getSelectedAxes: (state) => state.selectedAxes,
    getPeriod: (state) => state.period,
    getStartDate: (state) => state.startDate,
    getEndDate: (state) => state.endDate,
    getSelectedPeriod: (state) => state.selectedPeriod,
    getSelectedStartDatePicker: (state) => state.selectedStartDatePicker,
    getSelectedEndDatePicker: (state) => state.selectedEndDatePicker,
    getSources: (state) => state.sources,
    onclusiveSelected: (state) => state.sources.find(source => source.value === 'Onclusive')?.selected,
    talkwalkerSelected: (state) => state.sources.find(source => source.value === 'Talkwalker')?.selected,
    adaySelected: (state) => state.sources.find(source => source.value === 'Aday')?.selected
};

const actions = {
    setIsSidenavVisible({commit}, isSidenavVisible) {
        commit('SET_IS_SIDENAV_VISIBLE', isSidenavVisible);
    },
    setAxes({commit}, axes) {
        commit('SET_AXES', axes);
    },
    setSelectedAxes({commit}, selectedAxes) {
        commit('SET_SELECTED_AXES', selectedAxes);
    },
    addSelectedAxe({commit}, selectedAxe) {
        commit('ADD_SELECTED_AXE', selectedAxe);
    },
    removeSelectedAxe({commit}, selectedAxe) {
        commit('REMOVE_SELECTED_AXE', selectedAxe);
    },
    setPeriod({commit}, period) {
        commit('SET_PERIOD', period);
    },
    setStartDate({commit}, startDate) {
        commit('SET_START_DATE', startDate);
    },
    setEndDate({commit}, endDate) {
        commit('SET_END_DATE', endDate);
    },
    setSelectedPeriod({commit}, selectedPeriod) {
        commit('SET_SELECTED_PERIOD', selectedPeriod);
    },
    setSelectedStartDatePicker({commit}, selectedStartDatePicker) {
        commit('SET_SELECTED_START_DATE_PICKER', selectedStartDatePicker);
    },
    setSelectedEndDatePicker({commit}, selectedEndDatePicker) {
        commit('SET_SELECTED_END_DATE_PICKER', selectedEndDatePicker);
    },
    setSources({commit}, sources) {
        commit('SET_SOURCES', sources);
    }
}

const mutations = {
    SET_IS_SIDENAV_VISIBLE(state, isSidenavVisible) {
        state.isSidenavVisible = isSidenavVisible;
    },
    SET_AXES(state, axes) {
        state.axes = axes;
    },
    SET_SELECTED_AXES(state, selectedAxes) {
        state.selectedAxes = selectedAxes;
    },
    ADD_SELECTED_AXE(state, value) {
        state.selectedAxes.push(value);
    },
    REMOVE_SELECTED_AXE(state, value) {
        state.selectedAxes = state.selectedAxes.filter(axe => axe !== value);
    },
    SET_PERIOD(state, period) {
        state.period = period;
    },
    SET_START_DATE(state, startDate) {
        state.startDate = startDate;
    },
    SET_END_DATE(state, endDate) {
        state.endDate = endDate;
    },
    SET_SELECTED_PERIOD(state, selectedPeriod) {
        state.selectedPeriod = selectedPeriod;
    },
    SET_SELECTED_START_DATE_PICKER(state, selectedStartDatePicker) {
        state.selectedStartDatePicker = selectedStartDatePicker;
    },
    SET_SELECTED_END_DATE_PICKER(state, selectedEndDatePicker) {
        state.selectedEndDatePicker = selectedEndDatePicker;
    },
    SET_SOURCES(state, sources) {
        state.sources = sources;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};