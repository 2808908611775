<template>
  <section v-if="this.isDashboard"
           class="subContainer"
           :style="{ position: isSticky ? 'sticky' : 'static', top: isSticky ? '0' : 'auto' }">
    <div class="fr-container--fluid">
      <div class="fr-grid-row fr-grid-row--gutters fr-px-3w fr-pt-3w fr-mb-4w">
        <div class="fr-col-12 fr-pb-0">
          <breadcrumb :PageTitle="getPageTitle" :PageURL="selectedPage">
          </breadcrumb>
        </div>

        <div class="fr-col-12 fr-col-lg-9 subContainer__titleSection">
          <div>
            <h1 class="fr-h1 fr-mb-0">
              {{ getPageTitle }}
            </h1>
          </div>
        </div>

        <div class="fr-col-12 fr-col-lg-3 subContainer__btn">
          <button class="fr-btn fr-mx-1v subContainer__btn--filter" @click="openSidenav" aria-expanded=true
                  id="fr-btn--secondary-filter" title="Filtrer">
            <span class="fr-icon-filter-line subContainer__btn--filter--icon" aria-hidden="true"></span>
            Filtrer
          </button>
        </div>

        <div class="fr-col-12 fr-pt-0 subContainer__comparaisonLine">
          <p class="fr-text-xs fr-mt-1w fr-mr-2w fr-mb-0"><strong>{{
              this.isCompared ? "Comparaison :" :
                  "Filtres actifs :"
            }}</strong></p>

          <ul class="subContainer__comparaisonLine--parameters fr-p-0">
            <li class="fr-text-xs fr-mr-md-2w">
              {{ this.formatList(this.filteredTags(this.getSelectedAxes)) }}
            </li>
            <li class="fr-text-xs fr-mr-md-2w"
                :class="this.getStartDate ? '' : 'subContainer__comparaisonLine--parameters--display'">
              {{ `Du ${this.getStartDate}` }}
            </li>
            <li class="fr-text-xs fr-mr-md-2w"
                :class="this.getEndDate ? '' : 'subContainer__comparaisonLine--parameters--display'">
              {{ `Au ${this.getEndDate}` }}
            </li>
            <li class="fr-text-sm fr-mr-md-2w" v-if="this.getStartDate === undefined && this.getEndDate === undefined">
              {{ getPeriodLabel() }}
            </li>
          </ul>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Breadcrumb from "./dashboardPage/Breadcrumb.vue";

export default {
  name: "TitleBar",
  components: {
    "breadcrumb": Breadcrumb,
  },
  data() {
    return {
      isMainPage: true,
    }
  },
  computed: {
    ...mapGetters([
      'isDashboard',
      'isCompared',
      'isSticky',
      'getSelectedAxes',
      'getPeriod',
      'getStartDate',
      'getEndDate'
    ]),
    selectedPage() {
      const lastMatchedRoute = this.$route.matched[this.$route.matched.length - 1];
      return lastMatchedRoute ? lastMatchedRoute.name : 'Page par défaut';
    },
    getDict_PagesNames() {
      return {
        'vue-synthetique': 'Principaux indicateurs',
        'visibilite': 'Vue détaillée visibilité',
        'acteurs': 'Vue détaillée acteurs',
        'sujets-interets': 'Vue détaillée sujets d\'intérêts',
      }
    },
    getPageTitle() {
      const pageName = this.selectedPage;
      this.isSyntheticViewPage(this.selectedPage);
      return this.getDict_PagesNames[pageName] || 'Tableau de bord';
    },
  },
  methods: {
    ...mapActions([
      'setIsSidenavVisible'
    ]),
    openSidenav() {
      this.setIsSidenavVisible(true);
    },
    filteredTag(tag) {
      const tagMapping = {
        ["ALL"]: "Tous les axes",
        ["PLEIN EMPLOI"]: "Plein emploi et réindustrialisation",
        ["TRANSITION ECOLOGIQUE"]: "Transition écologique",
        ["NOUVEAUX PROGRES ET SERVICES PUBLICS"]: "Nouveaux progrès et services publics",
        ["ORDRE REPUBLICAIN ET ENGAGEMENT"]: "Ordre républicain et engagement",
      };
      let filteredTag;
      for (const [key, value] of Object.entries(tagMapping)) {
        if (tag.includes(key)) {
          filteredTag = value;
        }
      }
      return filteredTag;
    },
    filteredTags(tags) {
      return tags.map(tag => this.filteredTag(tag));
    },
    formatList(list) {
      return list.join(', ');
    },
    getPeriodLabel() {
      switch (this.getPeriod) {
        case "année":
          return "Moins d'un an";
        case "mois":
          return "Moins d'un mois";
        case "semaine":
          return "Moins d'une semaine";
      }
    },
    isSyntheticViewPage(selectedPage) {
      return selectedPage === 'vue-synthetique' ? this.isMainPage = true : this.isMainPage = false;
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/titleBar.scss";
</style>