<template>
  <div>
    <div id="app">
      <div v-if="this.isSidenavVisible" class="full-screen-overlay" @click="closeSidenav"></div>
      <app-skiplinks></app-skiplinks>
      <app-header></app-header>
      <app-title-bar></app-title-bar>
      <app-sidenav v-if="this.isSidenavVisible" />
      <main id="content" role="main" tabindex="-1" style="z-index:0; position: relative;">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import SkipLinks from './components/SkipLinks.vue';
import HeaderDsfr from './components/Header.vue';

import FooterDsfr from './components/Footer.vue';
import Sidenav from "./components/Sidenav.vue";
import {mapActions, mapGetters} from "vuex";
import TitleBar from "./components/TitleBar.vue";

export default {
  components: {
    "app-sidenav": Sidenav,
    "app-skiplinks": SkipLinks,
    "app-header": HeaderDsfr,
    "app-title-bar": TitleBar,
    "app-footer": FooterDsfr
  },
  computed: {
    ...mapGetters(['isSidenavVisible']),
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title
    },
  },
  methods: {
    ...mapActions([
        'setIsSidenavVisible'
    ]),
    closeSidenav() {
      this.setIsSidenavVisible(false);
    },
  }
}
</script>
<style>
@import 'styles/global.scss';
</style>